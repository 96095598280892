<template>
  <section id="home" class="imgagehome">
    <swiper-app />
  </section>
</template>

<script>
import SwiperApp from "./SwiperApp.vue";
export default {
  components: { SwiperApp },
};
</script>

<style lang="scss" scoped></style>
