<template>
  <slot>
    <div class="card"></div>
  </slot>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
